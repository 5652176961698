<template>
  <main class="text-center p-3">
    <h1>
      Přihlášení
    </h1>
    <validation-observer ref="observer" v-slot="{ handleSubmit }" tag="div" class="login-form">
      <b-form @submit.stop.prevent="handleSubmit(onSubmit)">
        <validation-provider
          v-slot="validationContext"
          name="e-mail"
          :rules="{ required: true, email: true }"
        >
          <b-form-group label-for="login-email">
            <template #label>
              <span class="h5">E-mailová adresa <span class="text-danger">*</span></span>
            </template>
            <b-form-input
              id="login-email"
              v-model="model.email"
              name="email"
              type="email"
              :state="getValidationState(validationContext)"
              aria-describedby="login-email-feedback"
              :disabled="busy"
              size="sm"
            />
            <b-form-invalid-feedback
              id="login-email-feedback"
              :state="getValidationState(validationContext) ? undefined : getValidationState(validationContext)"
            >
              <span class="small">{{
                validationContext.errors[0]
              }}</span>
            </b-form-invalid-feedback>
          </b-form-group>
        </validation-provider>
        <validation-provider
          v-slot="validationContext"
          name="password"
          :rules="{ required:true, min:6, regex: /^(?=.*[A-Za-z])(?=.*\d)[A-Za-z\d]{6,}$/ }"
        >
          <b-form-group label-for="login-password">
            <template #label>
              <span class="h5">Heslo</span>
            </template>
            <jb-password-input
              id="login-password"
              v-model="model.password"
              name="password"
              :state="getValidationState(validationContext)"
              aria-describedby="login-password-feedback"
              :disabled="busy"
              size="sm"
              :show-password.sync="showPassword"
            />
            <b-form-invalid-feedback
              id="login-password-feedback"
              :state="getValidationState(validationContext) ? undefined : getValidationState(validationContext)"
            >
              <span class="small">{{
                validationContext.errors[0]
              }}</span>
            </b-form-invalid-feedback>
          </b-form-group>
        </validation-provider>
        <b-row
          class="
          align-items-center
          justify-content-center justify-content-md-start
        "
        >
          <b-col cols="12" class="mt-2">
            <b-overlay
              :show="busy"
              rounded
              opacity="1"
              spinner-small
              spinner-variant="white"
              variant="primary"
              @hidden="onHidden"
            >
              <b-button
                ref="button"
                block
                variant="primary"
                type="submit"
              >
                Přihlásit
              </b-button>
            </b-overlay>
          </b-col>
        </b-row>
      </b-form>
      <div class="small text-muted mt-5 text-center">
        Tato stránka je chráněna reCAPTCHA a platí
        <a href="https://policies.google.com/privacy">zásady ochrany osobních údajů</a> a
        <a href="https://policies.google.com/terms">smluvní podmínky</a> společnosti Google.
      </div>
    </validation-observer>
  </main>
</template>

<script>
import { ValidationObserver, ValidationProvider, extend, localize } from 'vee-validate'
import { required, email, min, regex } from 'vee-validate/dist/rules'
import cs from 'vee-validate/dist/locale/cs.json'

localize('cs', cs)

extend('required', required)
extend('email', email)
extend('min', min)
extend('regex', regex)

export default {
  name: 'PagePrihlaseni',
  components: {
    JbPasswordInput: () => import('~/components/Core/JbPasswordInput/JbPasswordInput.vue'),
    ValidationObserver,
    ValidationProvider
  },
  layout: 'auth',
  data: () => ({
    showPassword: false,
    busy: false,
    model: {
      email: null,
      password: null
    }
  }),
  head: () => ({
    title: 'Přihlášení'
  }),
  methods: {
    async onSubmit () {
      this.busy = true
      try {
        await this.$auth.loginWith('laravelSanctum', {
          data: {
            email: this.model.email,
            password: this.model.password,
            'g-recaptcha-response': await this.$recaptcha.execute('login')
          }
        })
      } catch (e) {
        this.busy = false
      }
    },
    getValidationState ({
      dirty,
      validated,
      valid = null
    }) {
      return dirty || validated ? (valid ? null : false) : null
    },
    onHidden () {
      // Return focus to the button once hidden
      this.$refs.button.focus()
    }
  }
}
</script>
